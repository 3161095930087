<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="justify-start flex-grow w-full text-yellow-50 p-3 pb-1">
        <div class="flex flex-col items-center">
          <div
            class="px-2 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-2 lg:px-8 lg:py-15"
          >
            <div
              class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-gray-100"
            >
              <h2
                class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
              >
                เดิมพันเลขท้าย Hash ของ Binance Smart Chain คู่ - คี่
              </h2>
            </div>
          </div>
        </div>

        <div
          class="px-4 -mt-10 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md md:px-4 lg:px-2 lg:py-1"
        >
          <div
            class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12 text-gray-100"
          >
            <div>
              <h4
                class="inline-block px-3 py-px mb-5 text-md font-semibold tracking-wider text-teal-900 rounded-full bg-teal-accent-400"
              >
                ปัจจุบันอยู่ Block ที่
              </h4>
            </div>
            <h2
              class="max-w-lg font-sans text-3xl font-bold leading-none tracking-tight sm:text-4xl md:mx-auto"
            >
              <span class="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="247432cb-6e6c-4bec-9766-564ed7c230dc"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7"></circle>
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#247432cb-6e6c-4bec-9766-564ed7c230dc)"
                    width="52"
                    height="24"
                  ></rect>
                </svg>
              </span>
              # {{ getOnBlock }}
            </h2>
            <h4
              class="inline-block px-3 py-px mt-5 text-md font-semibold tracking-wider text-teal-900 rounded-full bg-teal-accent-400"
            >
              เดิมพันเลขท้าย Hash ของ Binance Smart Chain ใน Block ที่ {{ bet }}
            </h4>
          </div>
        </div>

        <div class="flex flex-col items-center">
          <div>
            <div class="grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-3">
              <button @click="predict(pair)">
                <div
                  class="bg-gradient-to-r from-blue-500 to-green-500 overflow-hidden shadow rounded-lg w-56 sm:w-60 relative"
                >
                  <img
                    :src="getCurrency['BNB'].logo"
                    alt="btc logo"
                    class="h-24 w-24 rounded-full absolute opacity-50 -top-6 -right-6 md:-right-4"
                  />
                  <div class="px-4 py-8 sm:p-9">
                    <dl>
                      <dd
                        class="mt-1 text-3xl sm:text-3xl leading-9 font-semibold text-white"
                      >
                        เดิมพันคู่
                      </dd>
                    </dl>
                  </div>
                </div>
              </button>

              <button @click="predict('odd')">
                <div
                  class="bg-gradient-to-r from-blue-500 to-red-500 overflow-hidden shadow rounded-lg w-56 sm:w-60 relative"
                >
                  <img
                    :src="getCurrency['BNB'].logo"
                    alt="btc logo"
                    class="h-24 w-24 rounded-full absolute opacity-50 -top-6 -right-6 md:-right-4"
                  />
                  <div class="px-4 py-8 sm:p-9">
                    <dl>
                      <dd
                        class="mt-1 text-3xl sm:text-3xl leading-9 font-semibold text-white"
                      >
                        เดิมพันคี่
                      </dd>
                    </dl>
                  </div>
                </div>
              </button>

              <button @click="predict('noNumber')">
                <div
                  class="bg-gradient-to-r from-purple-500 to-gray-500 overflow-hidden shadow rounded-lg w-56 sm:w-60 relative"
                >
                  <img
                    :src="getCurrency['BNB'].logo"
                    alt="btc logo"
                    class="h-24 w-24 rounded-full absolute opacity-50 -top-6 -right-6 md:-right-4"
                  />
                  <div class="px-4 py-8 sm:p-9">
                    <dl>
                      <dd
                        class="mt-1 text-3xl sm:text-3xl leading-9 font-semibold text-white"
                      >
                        ไม่มีแต้ม
                      </dd>
                    </dl>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>

        <div
          class="px-4 mt-10 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md md:px-4 lg:px-2 lg:py-1"
        >
          <img class="h-8 inline" :src="require(`@/assets/chain/bsc.png`)" />
          0.1
          <br />
          {{ getBalance.BNB.balance }}
        </div>

        <div
          class="px-4 mt-10 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md md:px-4 lg:px-2 lg:py-1"
        >
          <div class="bg-gray-800 py-2 px-3 rounded max-w-5xl w-full flex-none">
            <div class="text-base font-medium">
              <span class="mr-4 font-bold">Bet History</span>
              <i class="fad fa-wallet mr-2 z-0"></i>
              <span
                class="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-400"
              >
                {{ address }}
                {{ getUser.address.slice(0, 6) }}...{{
                  getUser.address.slice(-4)
                }}
              </span>
            </div>
          </div>

          <!-- {{ table_rows }} -->

          <div class="flex-glow max-w-5xl w-full">
            <div v-if="!ready" class="flex flex-col">
              <loader class="h-40"></loader>
              <p class="text-center">Loading...</p>
            </div>
            <div v-else>
              <div v-if="table_rows.length == 0">
                <div class="flex flex-col">
                  <chameleon class="h-40"></chameleon>
                  <p class="text-center">You don't play a bet</p>
                </div>
              </div>
              <div v-else class="w-full items-center">
                <div v-for="t in table_rows" v-bind:key="t.id">
                  <div
                    v-if="t.status == 'WIN' || t.status == 'LOST'"
                    class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
                  >
                    <div class="flex-grow flex sm:flex-row">
                      <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                        เดิมพัน block ที่
                        <a
                          class="text-baseline text-blue text-blue-400 mr-4"
                          v-bind:href="`https://bscscan.com/block/${t.block}`"
                          target="_blank"
                        >
                          {{ t.block }}
                        </a>
                      </div>

                      <div class="text-sm sm:text-base md:block">
                        <span class="text-xs text-gray-400"> ทำนาย: </span>
                        <span v-if="t.predict == 'pair'"> คู่ </span>
                        <span v-else-if="t.predict == 'odd'"> คี่ </span>
                        <span v-else> ไม่มีแต้ม </span>

                        <span class="text-xs">, Hash: </span>
                        <div class="r-4 text-gray-500 text-sm sm:inline-block">
                          <a
                            target="_blink"
                            v-bind:href="`https://bscscan.com/block/${t.block}`"
                            class="text-blue text-blue-400"
                          >
                            {{ t.hash.slice(0, 6) }}...{{ t.hash.slice(-8) }}
                          </a>

                          ผลที่ออก:
                          <span
                            class="text-xs text-blue-500"
                            v-if="
                              t.one_hash == '0' ||
                              t.one_hash == '2' ||
                              t.one_hash == '4' ||
                              t.one_hash == '6' ||
                              t.one_hash == '8'
                            "
                          >
                            คู่
                          </span>

                          <span
                            class="text-xs text-blue-500"
                            v-else-if="
                              t.one_hash == '1' ||
                              t.one_hash == '3' ||
                              t.one_hash == '5' ||
                              t.one_hash == '7' ||
                              t.one_hash == '9'
                            "
                          >
                            คี่
                          </span>
                          <span class="text-xs text-blue-500" v-else>
                            ไม่มีแต้ม
                          </span>
                        </div>
                        <div
                          class="r-4 text-gray-500 text-sm sm:inline-block ml-1"
                        >
                          , {{ t.reward }} {{ t.currency }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
                    >
                      <div>
                        {{ t.amount.toFixed(8) / 1 }} {{ t.currency }}
                        <img
                          class="inline-block h-4 ml-2"
                          :src="`/tokens/${t.currency}.png`"
                        />
                        <div
                          v-bind:class="
                            'text-xs sm:inline-block text-center font-semibold mt-1 py-1 rounded uppercase ml-10 sm:ml-2 w-24 ' +
                            t.label
                          "
                        >
                          {{ t.status }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="flex w-full bg-gray-500 bg-opacity-10 my-1 hover:bg-gray-500 hover:bg-opacity-25 p-2 rounded-md"
                    >
                      <div class="flex-grow flex sm:flex-row">
                        <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                          เดิมพัน block ที่
                          <a
                            class="text-baseline text-blue text-blue-400 mr-4"
                            v-bind:href="`https://bscscan.com/block/${t.block}`"
                            target="_blank"
                          >
                            {{ t.block }}
                          </a>
                        </div>

                        <div class="sm:mr-2 text-gray-500 text-sm sm:text-base">
                          ปัจจุบัน block ที่
                          <a
                            class="text-baseline text-blue text-blue-400 mr-4"
                            v-bind:href="`https://bscscan.com/block/${getOnBlock}`"
                            target="_blank"
                          >
                            {{ getOnBlock }}
                          </a>
                        </div>
                      </div>
                      <div
                        class="flex-none text-right flex flex-col sm:flex-row text-sm sm:text-base"
                      >
                        <div>
                          {{ t.amount.toFixed(8) / 1 }} {{ t.currency }}
                          <img
                            class="inline-block h-4 ml-2"
                            :src="`/tokens/${t.currency}.png`"
                          />
                          <div
                            v-bind:class="
                              'text-xs sm:inline-block text-center font-semibold mt-1 py-1 rounded uppercase ml-10 sm:ml-2 w-24 ' +
                              t.label
                            "
                          >
                            {{ t.status }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <div
                    class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 hover:bg-gray-500 hover:bg-opacity-25 border-none cursor-pointer"
                    v-on:click="getdata()"
                    v-if="!nomore"
                  >
                    Load more...
                  </div>
                  <div
                    class="text-sm mt-2 px-6 py-2 border rounded text-yellow-50 border-none"
                    v-else
                  >
                    - No more -
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="px-4 mt-10 py-1 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-md md:px-4 lg:px-2 lg:py-1"
        >
          Live Block
          <div
            class="w-full shadow-2xl subpixel-antialiased rounded bg-black border-black bg-opacity-30 h-auto"
          >
            <div
              class="pl-1 pt-1 text-green-200 font-mono text-xs livetx text-left overflow"
              id="console"
            >
              <div v-for="block in blocks_data" v-bind:key="block.block">
                <p class="pb-1" :style="`color: ` + block.color_one_hash">
                  Block Number: {{ block.block }} , Hash:
                  {{ block.hash.slice(0, 7) }}.......{{
                    block.hash.slice(block.hash.length - 7, block.hash.length)
                  }}
                  , เลขท้ายที่ออก: {{ block.one_hash }} , รางวัลที่ออก:

                  <a
                    v-if="
                      block.one_hash == 0 ||
                      block.one_hash == 2 ||
                      block.one_hash == 4 ||
                      block.one_hash == 6 ||
                      block.one_hash == 8
                    "
                  >
                    ผลออกคู่
                  </a>
                  <a
                    v-else-if="
                      block.one_hash == 1 ||
                      block.one_hash == 3 ||
                      block.one_hash == 5 ||
                      block.one_hash == 7 ||
                      block.one_hash == 9
                    "
                  >
                    ผลออกคี่
                  </a>
                  <a v-else> ไม่มีแต้ม </a>
                  <!-- {{ block.one_hash }} -->
                </p>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

        <footer>
          <StickFooter />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import { mapGetters, mapActions } from "vuex";
import { POOL_ADDRESS, VERSION, EXPLORER } from "../../../config";

import chameleon from "@/assets/svg/chameleon.vue";
import loader from "@/assets/svg/loader.vue";
import user from "@/api/user";
import func from "@/widgets/func";
import label from "@/widgets/label";
import moment from "moment";
import nativeToast from "native-toast";

import info from "@/api/info";

import blocks from "@/api/blocks";

export default {
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    ConnectWallet,
    StickFooter,
    FundSummary,
    loader,
    chameleon,
    loader,
    chameleon,
  },
  computed: mapGetters(["getCurrency", "getOnBlock", "getUser", "getBalance"]),

  data() {
    return {
      funds: [],
      ready: false,
      getOnBlock: this.getOnBlock,
      bet: parseFloat(this.getOnBlock) + 20,
      blocks_data: [],
      bet_detail: [],
      address: this.$route.params.address || null,
      ready: false,
      page: 0,
      nomore: false,
      table_rows: [],
      expanded: null,
      explorer: EXPLORER,
    };
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    expand(id) {
      if (this.expanded == id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },

    async getData() {
      info.fundsummary().then((res) => {
        res.data.forEach((e) => {
          var a = label.type(e._id.type);
          e.label = a.label;

          this.funds.push(e);
        });
        this.ready = true;
      });

      await blocks
        .getTransactionOneHash()
        .then((response) => {
          this.blocks_data = response.data;
        })
        .catch((error) => {});

      await blocks
        .bethistory({ page: this.page, address: this.getUser.address })
        .then((res) => {
          this.ready = true;
          if (res.data.length == 0) {
            this.nomore = true;
          } else {
            console.log(res.data);
            res.data.forEach((e) => {
              e.createdAt = moment(e.createdAt).format("YYYY-MM-DD, HH:mm:ss");

              var a = label.type(e.status);

              e.label = a.label;

              this.table_rows.push(e);
            });
            this.page = this.page + 1;
          }
        })
        .catch((error) => {
          nativeToast({
            message: error.response.data.error,
            position: "top",
            timeout: 3000,
            type: "error",
          });
        });
    },
    async predict(p) {
      await blocks
        .bet_oneHash({
          user_id: this.getUser._id,
          amount: "0.01",
          predict: p,
          block: this.getOnBlock,
          currency: "BNB",
          address: this.getUser.address,
        })
        .then((response) => {})
        .catch((error) => {});
    },
    async get_bet_transaction() {
      await blocks
        .transactions_bet_oneHash()
        .then((response) => {
          this.bet_detail = response.data;
        })
        .catch((error) => {});
    },
  },
  created() {
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }

    this.sockets.unsubscribe("blocks");
    this.sockets.subscribe("blocks", (data) => {
      this.getOnBlock = data;
      this.bet = parseFloat(data) + 20;
    });

    // this.sockets.unsubscribe("blocks_live");
    // this.sockets.subscribe("blocks_live", (data) => {
    //   this.blocks_data = data;
    // });

    this.getData();
    this.get_bet_transaction();
  },
};
</script>
